/* landing.module.css */
.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.logo {
  display: flex;
  justify-content: center;
}
button {
  width: 20rem;
}

.banner {
  display: flex;
  margin-top: 5rem;
  padding: 0 2rem;
}

.textSection {
  flex: 1;
  margin-top: 5rem;
}

.title {
  color: white;
  margin-bottom: 0;
}
.buttonContainer {
  display: flex;
  justify-content: left;
}

.highlight {
  color: var(--primary-main-color);
}

.description {
  color: #bababa;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50rem;
  position: relative;
}

.vector {
  width: 100%;
  height: 100%;
}

.banner1 {
  position: absolute;
  height: 80%;
  right: 10%;
  top: 10%;
}

.banner2 {
  position: absolute;
  height: 60%;
  left: 5%;
  bottom: 2%;
}

/* Media Queries */
@media (max-width: 1024px) {
  .banner {
    flex-direction: column;
    align-items: center;
  }

  .textSection {
    margin-top: 2rem;
    text-align: center;
  }

  .imageContainer {
    height: 40rem;
  }

  .banner1 {
    height: 70%;
    right: 5%;
    top: 15%;
  }

  .banner2 {
    height: 50%;
    left: 10%;
    bottom: 5%;
  }
}

@media (max-width: 768px) {
  .textSection {
    margin-top: 1rem;
  }

  .imageContainer {
    height: 30rem;
  }

  .banner1 {
    height: 60%;
    right: 5%;
    top: 20%;
  }

  .banner2 {
    height: 40%;
    left: 10%;
    bottom: 10%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1rem;
  }
  h1 {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 1.5;
  }
  .textSection {
    align-items: center;
    margin-top: 0;
  }
  .buttonContainer {
    justify-content: center;
  }

  .banner {
    padding: 0 1rem;
    margin-top: 1rem;
  }

  .imageContainer {
    margin-top: 1.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    position: relative;
  }

  .banner1 {
    box-sizing: border-box;
    position: absolute;
    height: 78%;
    top: 13%;
    width: 12rem;
  }

  .banner2 {
    box-sizing: border-box;
    position: absolute;
    height: 60%;
    width: 8rem;
    top: 40%;
  }
}
