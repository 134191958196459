/* Main Footer container */
.footer {
  width: 100%;
  background-color: var(--primary-main-color);
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

/* Inner Flex container */
.footerInner {
  display: flex;
  width: 80%;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebf5fd;
  padding-bottom: 1rem;
}

/* Common flex styles for columns */
.footerColumn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

/* Logo and text container */
.logoContainer {
  display: flex;
  align-items: center;
}

/* Logo text */
.footerLogoText {
  margin-left: 1rem;
  color: #ffffff;
  font-size: 1.5rem;
}

.footerLinksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerLinks {
  color: var(--primary-lightB-color);
  margin: 0.5rem 0;
  cursor: pointer;
  text-align: center;
}

/* Download buttons */
.downloadSvg {
  margin: 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.downloadSvg svg {
  width: 150px; /* Width can be adjusted based on design */
}

/* Footer CopyRight */
.footerCopyRight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: #ffffff;
}

.copyrightText {
  display: flex;
  align-items: center;
}

.copyrightText svg {
  margin-right: 0.5rem;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .footerInner {
    flex-direction: column;
    align-items: center;
  }
  .footerColumn {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footerLogoText {
    font-size: 1.2rem;
    text-align: center;
  }
  .footerLinksContainer {
    align-items: flex-start;
  }
  .footerLinks {
    padding-left: 0;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1rem 0;
  }
  .footerLogoText {
    font-size: 1rem;
  }
  .logoContainer {
    flex-direction: column;
    align-items: center;
  }
  .footerLinks {
    font-size: 0.9rem;
  }
  .downloadSvg svg {
    width: 120px;
  }
}
