@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --main-bg-color: #1e1e1e;
  --primary-main-color: #379ae6;
  --primary-lightB-color: #ebf5fd;
}

body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: var(--main-bg-color);
}

h1 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.5;
}

h2 {
  font-weight: 600;
  font-size: 2rem;
}
h3 {
  font-weight: 600;
  font-size: 1.5rem;
}

p {
  font-weight: 400;
  font-size: 1rem;
}

link {
  font-weight: 500;
  font-size: 1rem;
}
